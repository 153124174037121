import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import { Box, IconButton, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { FilterList, Mic } from '@mui/icons-material'
import { cleanObject } from '@trinity/utils'
import { FabricCardDescription } from '../../assets'
import { FabricCardList, Pagination, Search } from '../../components'
import { useGlobalState } from '../../hooks'
import { useFilters } from '../../hooks/useFilters'

interface FabricExplorerFabricsProps {
  fabrics: TrinityAPI.FabricExtendedType[]
  totalFabrics: number
  children?: React.ReactNode
}

export function FabricExplorerFabrics({ fabrics, totalFabrics, children = undefined }: FabricExplorerFabricsProps) {
  const { onMobile } = useGlobalState()
  const [params, setParams] = useSearchParams()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const shouldShowFabrics = checkForFilters(params)
  const { Filters, FilterChips } = useFilters({ open: drawerOpen, close: () => setDrawerOpen(false), filters })

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 0, laptop: 4 }}>
      <Filters>
        <input type='hidden' name='q' value={params.get('q') ?? ''} />
      </Filters>
      <Stack width={1}>
        <Stack
          direction={{ mobile: 'column', laptop: 'row-reverse' }}
          alignItems='flex-start'
          justifyContent='space-between'
          spacing={{ mobile: 4, laptop: 0 }}
          pb={4}
        >
          {children}
          {onMobile && (
            <Stack alignItems='center' width={1}>
              <Typography gutterBottom variant='h2'>
                Find a Fabric
              </Typography>
              <Typography variant='subtitle1'>Search or Filter for the fabric you want.</Typography>
            </Stack>
          )}
          <Stack direction='row' spacing={1} sx={{ width: { mobile: 1, laptop: 1.25 / 4 } }}>
            <Search<TrinityAPI.FabricAutocompleteType>
              name='q'
              placeholder={onMobile ? 'Search...' : 'Search fabric # or description...'}
              startAdornment={onMobile && <Mic color='primary' />}
              handleSubmit={v =>
                setParams(
                  params => {
                    params.set('q', v)
                    return params
                  },
                  { replace: true },
                )
              }
            >
              {({ suggestion, searchTerm }) => (
                <ListItem disablePadding>
                  <ListItemButton component={Link} to={String(suggestion.id)}>
                    <Stack direction='row' alignItems='center'>
                      <ListItemText primaryTypographyProps={{ variant: 'h6', width: 120 }}>
                        <Highlighter
                          autoEscape
                          searchWords={[searchTerm]}
                          textToHighlight={suggestion.trinityFabricNumber}
                          highlightTag='strong'
                        />
                      </ListItemText>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'smallBody2',
                          noWrap: true,
                          maxWidth: { mobile: 170, tablet: '100%', laptop: 140, desktop: 200 },
                        }}
                      >
                        <Highlighter
                          autoEscape
                          searchWords={[searchTerm]}
                          textToHighlight={suggestion.description ?? ''}
                          highlightTag='strong'
                        />
                      </ListItemText>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              )}
            </Search>
            {onMobile && (
              <IconButton
                color='primary'
                onClick={() => setDrawerOpen(true)}
                sx={{ border: 'primary', borderRadius: 1, px: 2.5 }}
              >
                <FilterList />
              </IconButton>
            )}
          </Stack>
        </Stack>
        {!onMobile && (
          <Typography gutterBottom variant='h3'>
            Find a Fabric
          </Typography>
        )}
        {shouldShowFabrics ? (
          <Stack spacing={4} pt={4}>
            <FilterChips />
            <FabricCardList fabrics={fabrics} baseUrl='/fabric-explorer/fabrics/:fabricId' />
            <Pagination total={totalFabrics} pageSizeOptions={[24, 48, 96]} />
          </Stack>
        ) : (
          <>
            <Typography variant='body1' sx={{ display: { mobile: 'none', laptop: 'initial' } }}>
              Start by searching or filtering for the fabric you are looking for.
            </Typography>
            <Box
              component='img'
              src={FabricCardDescription}
              alt='Fabric Card Description'
              sx={{ alignSelf: 'center', pt: 4, maxWidth: 1 }}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}

//* HELPERS
const filters = {
  price: [
    { title: '$', filterParam: 'priceTier', value: '1' },
    { title: '$$', filterParam: 'priceTier', value: '2' },
    { title: '$$$', filterParam: 'priceTier', value: '3' },
    { title: '$$$$', filterParam: 'priceTier', value: '4' },
    { title: 'discounted', filterParam: 'discounted', value: '1' },
  ],
  usage: [
    { title: 'clothing', filterParam: 'namedFilter', value: 'suiting' },
    { title: 'shirting', filterParam: 'namedFilter', value: 'shirting' },
    { title: 'outerwear', filterParam: 'namedFilter', value: 'outerwear' },
    { title: 'lining', filterParam: 'namedFilter', value: 'lining' },
    { title: 'trim', filterParam: 'namedFilter', value: 'trim' },
  ],
  readiness: [
    { title: 'at factory', filterParam: 'fabricType', value: 'trinity' },
    { title: 'ship from vendor', filterParam: 'fabricType', value: 'single-length' },
  ],
  factory: [
    { title: 'T2iD', filterParam: 'factory', value: 'T2iD' },
    { title: 'iD', filterParam: 'factory', value: 'iD' },
  ],
  pattern: [
    { title: 'solid', filterParam: 'patternId', value: '1' },
    { title: 'stripe', filterParam: 'patternId', value: '2' },
    { title: 'check', filterParam: 'patternId', value: '3' },
    { title: 'plaid', filterParam: 'patternId', value: '4' },
    { title: 'print', filterParam: 'patternId', value: '5' },
    { title: 'windowpane', filterParam: 'patternId', value: '6' },
    { title: 'other', filterParam: 'patternId', value: '7' },
  ],
  color: [
    { title: 'black', filterParam: 'primaryColorId', value: '1' },
    { title: 'gray', filterParam: 'primaryColorId', value: '2' },
    { title: 'white', filterParam: 'primaryColorId', value: '3' },
    { title: 'blue', filterParam: 'primaryColorId', value: '4' },
    { title: 'red', filterParam: 'primaryColorId', value: '5' },
    { title: 'green', filterParam: 'primaryColorId', value: '6' },
    { title: 'yellow', filterParam: 'primaryColorId', value: '7' },
    { title: 'brown', filterParam: 'primaryColorId', value: '8' },
  ],
  weave: [
    { title: 'plain', filterParam: 'weaveId', value: '1' },
    { title: 'herringbone', filterParam: 'weaveId', value: '2' },
    { title: 'dobby', filterParam: 'weaveId', value: '3' },
    { title: 'poplin', filterParam: 'weaveId', value: '4' },
    { title: 'broadcloth', filterParam: 'weaveId', value: '5' },
    { title: 'chambray', filterParam: 'weaveId', value: '6' },
    { title: 'twill', filterParam: 'weaveId', value: '7' },
    { title: 'basket', filterParam: 'weaveId', value: '8' },
    { title: 'other', filterParam: 'weaveId', value: '9' },
  ],
}

const checkForFilters = (rawParams: URLSearchParams) => {
  const paramsObject = Object.fromEntries(rawParams.entries())
  const params = cleanObject(paramsObject)
  return Object.keys(params).length > 0
}
