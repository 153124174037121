import { useEffect, useState } from 'react'
import { Form, Outlet, useFetchers, useNavigate } from 'react-router-dom'
import { Button, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { useGlobalState } from '../../hooks'
import { BackButton, Dialog, ImageCardList } from '../../components'

interface StyleflowRackProps {
  garments: TrinityAPI.VisuzlierRackType[]
  stylepages: TrinityAPI.VisualizerStylepageType[]
}

export function StyleflowRack({ garments, stylepages }: StyleflowRackProps) {
  const { setToastInfo } = useGlobalState()
  const [stylepageDialog, setStylepageDialog] = useState(false)
  const navigate = useNavigate()
  const fetchers = useFetchers()
  const fetcher = fetchers.find(fetcher => fetcher.key === ImageCardList.FETCHER_KEY)
  const items = garments.map(item => ({
    key: item.id,
    image: item.image,
    inStock: item.fabric.stockStatus.t2iD?.inStock,
    dividerText: item.baseModel.description,
    primaryText: `${item.fabric.trinityNumber} (${item.fabric.position})`,
    secondaryText: item.fabric.description,
    hoverItems: [
      item.lining ? ['Lining', item.lining.trinityNumber] : [],
      item.button ? ['Button', item.button.description] : [],
      item.thread ? ['Thread', item.thread.description] : [],
      item.ribknit ? ['Ribknit', item.ribknit.description] : [],
      item.zipper ? ['Zipper', item.zipper.description] : [],
    ],
    item,
  }))

  useEffect(() => {
    if (fetcher?.data) {
      setToastInfo({ show: true, severity: 'success', message: fetcher?.data?.message })
    }
  }, [fetcher, setToastInfo])

  return (
    <Stack spacing={4}>
      <BackButton to='/styleflow/collections'>Continue Browsing</BackButton>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        spacing={4}
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant='h3'>Rack</Typography>
        <Stack direction='row' spacing={4}>
          <Button
            disabled={garments.length < 1}
            size='small'
            variant='outlined'
            onClick={() => setStylepageDialog(true)}
          >
            Save As Stylepage
          </Button>
          <Button disabled={garments.length < 1} size='small' onClick={() => navigate('start-order')}>
            Start New Order
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <ImageCardList items={items} actions={['delete', 'edit']}>
        {garment => (
          <>
            <input type='hidden' name='id' value={garment.id} />
            <input type='hidden' name='baseModelId' value={garment.baseModel.id} />
            <input type='hidden' name='garmentType' value={garment.garmentType} />
            <input type='hidden' name='fabricId' value={garment.fabric.id} />
            <input type='hidden' name='liningId' value={garment.lining?.id} />
            <input type='hidden' name='buttonId' value={garment.button?.id} />
            <input type='hidden' name='threadId' value={garment.thread?.id} />
            <input type='hidden' name='ribknitId' value={garment.ribknit?.id} />
            <input type='hidden' name='zipperId' value={garment.zipper?.id} />
          </>
        )}
      </ImageCardList>
      <Dialog open={stylepageDialog} onClose={() => setStylepageDialog(false)}>
        <Form method='POST'>
          <Stack p={4} spacing={6}>
            <Typography variant='h4'>Save As Stylepage</Typography>
            <TextField required label='Stylepage Title' name='name' />
            <Button type='submit' name='intent' value='save'>
              Save
            </Button>
          </Stack>
        </Form>
      </Dialog>
      <SaveStylepageDialog open={stylepageDialog} close={() => setStylepageDialog(false)} stylepages={stylepages} />
      <Outlet />
    </Stack>
  )
}

interface DialogProps {
  open: boolean
  close: () => void
  stylepages: TrinityAPI.VisualizerStylepageType[]
}

function SaveStylepageDialog({ open, close, stylepages }: DialogProps) {
  const [selectedStylepage, setSelectedStylepage] = useState('')
  const [stylepageName, setStylepageName] = useState('')
  const [isDuplicate, setIsDuplicate] = useState(false)
  const isDisabled = !(selectedStylepage || stylepageName) || isDuplicate

  useEffect(() => {
    if (selectedStylepage) {
      setStylepageName('')
    }
  }, [selectedStylepage])

  useEffect(() => {
    setIsDuplicate(stylepages.some(stylepage => stylepage?.name === stylepageName))
  }, [stylepageName, stylepages])

  return (
    <Dialog open={open} onClose={close} PaperProps={{ sx: { px: 4 } }}>
      <Form method='POST'>
        <Stack p={4} spacing={6}>
          <Typography variant='h4'>Save As Stylepage</Typography>
          <TextField
            select
            value={selectedStylepage}
            label='Select Stylepage'
            name='stylepageId'
            onChange={e => setSelectedStylepage(e.target.value)}
          >
            <MenuItem value=''>New Stylepage</MenuItem>
            {stylepages.map(stylepage => (
              <MenuItem key={stylepage?.id} value={stylepage?.id}>
                {stylepage?.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={Boolean(selectedStylepage)}
            error={isDuplicate}
            required
            label='New Stylepage Name'
            name='name'
            value={stylepageName}
            onChange={e => setStylepageName(e.target.value)}
            helperText={isDuplicate ? 'You already have a Stylepage with this name' : null}
          />
          <Button disabled={isDisabled} type='submit' name='intent' value='save'>
            Save
          </Button>
        </Stack>
      </Form>
    </Dialog>
  )
}
