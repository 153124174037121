import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'

export function ModelLibrary() {
  return (
    <Stack spacing={4} pt={{ mobile: 1, laptop: 8 }} px={{ mobile: 1, laptop: 4 }}>
      <Typography variant='h2'>Model Library</Typography>
      <Divider />
      <Box>
        <Outlet />
      </Box>
    </Stack>
  )
}

export function ModelImages({ models }: { models: TrinityAPI.BaseModelType[] }) {
  const navigate = useNavigate()

  return (
    <Stack>
      <Typography variant='link' onClick={() => navigate('/library/models')} sx={{ zIndex: 1 }}>
        Garment Type
      </Typography>
      <Grid
        container
        direction={{ mobile: 'column', laptop: 'row' }}
        alignItems='center'
        rowSpacing={{ mobile: 4, laptop: 10 }}
        columnSpacing={10}
        pt={4}
      >
        {models.map(model => (
          <Grid item key={model.id} xs={1} md={4} width={1}>
            <Stack spacing={3} alignItems='center' pb={5} px={3}>
              <img src={model.image} alt={model.description} height={300} />
              <Stack alignItems='center'>
                <Typography gutterBottom variant='body3'>
                  {model.description}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}
