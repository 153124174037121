import { Link } from 'react-router-dom'
import { Box, ImageList, ImageListItem, ImageListItemBar, Stack, Typography } from '@mui/material'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { ImageNotFound } from '../../assets'
import { FabricFavorite } from '../../features/FabricExplorer/FabricFavorite'
import { Expand } from '../Animations'

export function FabricCardList({
  fabrics,
  isBasic = false,
  baseUrl = '/fabrics/:fabricId',
  onClick = undefined,
}: FabricCardListProps) {
  return (
    <ImageList
      rowHeight={318}
      gap={32}
      sx={{
        justifyItems: 'center',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr)) !important',
        overflow: 'visible',
      }}
    >
      {fabrics.length < 1 ? (
        <Typography variant='body1'>No Results Found</Typography>
      ) : (
        fabrics.map(fabric => (
          <FabricCard key={fabric.id} fabric={fabric} isBasic={isBasic} baseUrl={baseUrl} onClick={onClick} />
        ))
      )}
    </ImageList>
  )
}

export function FabricCard({ baseUrl, onClick = undefined, ...props }: FabricCardProps) {
  const fabricId = String(props.fabric.id)
  const url = baseUrl.replace(':fabricId', fabricId).replace('fabricId=', `fabricId=${fabricId}`)

  if (onClick) {
    return (
      <Expand>
        <Box onClick={() => onClick(fabricId)}>
          <BaseFabricCard {...props} />
        </Box>
      </Expand>
    )
  }

  return (
    <Expand>
      <Link to={url} style={{ textDecoration: 'none' }}>
        <BaseFabricCard {...props} />
      </Link>
    </Expand>
  )
}

//* -------------------- COMPONENTS -------------------- *//
function BaseFabricCard(props: BaseFabricCardProps) {
  const { fabric, isBasic } = props

  return (
    <ImageListItem
      sx={{
        boxShadow: theme => theme.elevation.autocomplete,
        border: 'n100',
        width: 241,
        borderRadius: 2,
        '& img': {
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
        },
      }}
    >
      <img
        src={`${fabric.picarioUrl}&height=500`}
        alt={fabric.description ?? fabric.trinityFabricNumber}
        loading='lazy'
        onError={e => (e.currentTarget.src = ImageNotFound)}
      />
      <ImageListItemBar
        title={<FactoryStatus {...props} />}
        sx={{
          mb: isBasic ? 7.75 : 8.25,
          background: 'none',
          boxShadow: theme => theme.elevation.fabricImage,
          height: 250,
          alignItems: 'flex-end',
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
        }}
      />
      <ImageListItemBar
        title={<FabricDescription {...props} />}
        subtitle={
          <Typography noWrap variant='smallBody2'>
            {fabric.description}
          </Typography>
        }
        position='below'
        sx={{ pl: 2 }}
      />
    </ImageListItem>
  )
}
function FactoryStatus({ fabric, isBasic }: BaseFabricCardProps) {
  if (isBasic) return null

  return (
    <Stack direction='row' justifyContent='flex-end'>
      <AvailabilityIcon inStock={fabric.factories.t2iD?.inStock} />
      <Typography variant='smallBody1' color='grey.A100' sx={{ px: 1 }}>
        T2iD
      </Typography>
      <AvailabilityIcon inStock={fabric.factories.iD?.inStock} />
      <Typography variant='smallBody1' color='grey.A100' sx={{ px: 1 }}>
        iD
      </Typography>
    </Stack>
  )
}

function FabricDescription({ fabric, isBasic }: BaseFabricCardProps) {
  const discountStyles =
    !isBasic && fabric.discount
      ? { bgcolor: 'secondary.main', py: 1, pr: 1, ml: fabric.position ? 0.25 : 3, pl: 0.25 }
      : { pr: 2 }
  const variant = !isBasic && fabric.discount ? 'smallButton' : 'subtitle1'
  const position = fabric.position ?? 'N/A'
  const displayText = isBasic
    ? `(${position})`
    : fabric.discount
      ? `${Math.ceil(Number(fabric.discount))}% off`
      : '$'.repeat(Number(fabric.priceTier))

  return (
    <Stack direction='row' alignItems='center'>
      <Typography noWrap variant='h6' sx={{ overflow: 'visible', mr: 1 }}>
        {fabric.trinityFabricNumber} {fabric.position ? `(${fabric.position})` : null}
      </Typography>
      {!isBasic && <FabricFavorite fabric={fabric} />}
      <Typography variant={variant} align='right' sx={{ width: 1, ...discountStyles }}>
        {displayText}
      </Typography>
    </Stack>
  )
}

function AvailabilityIcon({ inStock = false }: { inStock?: boolean }) {
  const Icon = inStock ? CheckCircle : Cancel

  return (
    <Box
      sx={{
        height: 10,
        width: 11,
        mt: '1px',
        ml: 2,
        borderRadius: '50%',
        bgcolor: 'common.white',
      }}
    >
      <Icon sx={{ fontSize: 16, color: inStock ? 'success.dark' : 'error.dark', mt: -0.5, mb: 0.5, ml: -0.25 }} />
    </Box>
  )
}

//* -------------------- TYPES -------------------- *//
type FabricsType = (TrinityAPI.FabricExtendedType | TrinityAPI.FabricFullType) & { position?: number }
type UrlType = ':fabricId' | 'fabricId='

interface FabricCardListProps {
  fabrics: FabricsType[]
  isBasic?: boolean
  baseUrl?: `/${string}${UrlType}${string}`
  onClick?: (fabricId: string) => void
}

interface FabricCardProps
  extends Required<Pick<FabricCardListProps, 'baseUrl' | 'isBasic'>>,
    Pick<FabricCardListProps, 'onClick'> {
  fabric: FabricsType
}

interface BaseFabricCardProps extends Pick<FabricCardProps, 'fabric' | 'isBasic'> {}
